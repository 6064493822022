import 'slick-carousel'
import momemt from 'moment';

jQuery( document ).ready(function($) {

  function formatTime(seconds) {
    let minutes
    minutes = Math.floor(seconds / 60);
    minutes = (minutes >= 10) ? minutes : "0" + minutes;
    seconds = Math.floor(seconds % 60);
    seconds = (seconds >= 10) ? seconds : "0" + seconds;
    return minutes + ":" + seconds;
  }

  // Mobile Menu
  $('.menu-trigger').click(function(){
    $(this).toggleClass('active')
    $('.menu-menu-container').toggleClass('active')
  })

  // Homepage Hero Slider 
  $('.slider-hero').slick({
    slidesToShow: 1,
    slidesToScroll: 1,
    mobileFirst: true,
    prevArrow: '<button type="button" aria-label="slick arrow" class="slick-prev"><i class="fas fa-chevron-left"></button>',
    nextArrow: '<button type="button" aria-label="slick arrow" class="slick-next"><i class="fas fa-chevron-right"></button>',
    autoplay: true,
    accessibility: true,
  });

  // Homepage Events
  $.ajax( {
    url: 'https://rest.bandsintown.com/artists/frenchkiss%20records/events?app_id=45PRESS_FRENCHKISS_RECORDS',
    method: 'GET',
    dataType: 'json',
    error: () => {
      alert( 'Error fetching events!' );
    },
    success: data => {
    const events = $( '#events' );
    let html = '';
    if ( data.length ) {
      for ( let event of data ) {
        let region = event.venue.region?event.venue.region:event.venue.country;
        let location = event.venue.city + ', ' + region;
        if ( location === ', ' ) {location = '';}
        if ( event.venue.name.toLowerCase() === 'streaming live' ) {location = 'Online';}
        html += '<div class="event">';
        html += '  <div class="event-date">' + momemt( event.datetime ).format( 'MMM D' ) + '</div>';
        html += '  <div class="event-location">' + location + '</div>';
        html += '  <div class="event-venue">' + event.venue.name + '</div>';
        html += '  <div class="event-links">';
        for ( let offer of event.offers ) {
          html += '  <a href="' + offer.url + '" target="_blank" class="btn btn-red-outline btn-rounded btn-small">' + offer.type + '</a>';
        }
        html += '  </div>';
        html += '</div>';
      }
      events.html( html );
      } else {
        events.html( 'There are no upcoming tour dates.' );
      }
    }
  });

  // Audio player
  $('.play').click(function(){
    $(this).hide()
    const audioItem = $(this).parent().find('audio').get(0)
    const track = $(this).parent()
    const trackLength = formatTime(audioItem.duration)
    audioItem.play()
    track.addClass('playing')
    track.find('.play-pause').show()
    track.find('.time-full').html(trackLength)
    track.find('.time-current').html(formatTime(audioItem.currentTime))
    window.setInterval(function(){
      track.find('.time-current').html(formatTime(audioItem.currentTime))
    }, 1000);
  })
  $('.play-pause').click(function(){
    const audioItem = $(this).parent().find('audio').get(0)
    const track = $(this).parent()
    if(audioItem.paused && audioItem.currentTime > 0 && !audioItem.ended) {
      audioItem.play();
      track.removeClass('paused')
      track.addClass('playing')
    }else {
      audioItem.pause();
      track.removeClass('playing')
      track.addClass('paused')
   }
  })

  if($('#bitUrl').length > 0){
    const bitUrl =$('#bitUrl').html()
    $.ajax( {
      url: bitUrl,
      method: 'GET',
      dataType: 'json',
      error: () => {
        alert( 'Error fetching events!' );
      },
      success: data => {
      const events = $( '#artist-events' );
      let html = '';
      if ( data.length ) {
        for ( let event of data ) {
          let region = event.venue.region?event.venue.region:event.venue.country;
          let location = event.venue.city + ', ' + region;
          if ( location === ', ' ) {location = '';}
          if ( event.venue.name.toLowerCase() === 'streaming live' ) {location = 'Online';}
          html += '<div class="event">';
          html += '<div class="event-date">' + momemt( event.datetime ).format( 'MMM D' ) + '</div>';
          html += '<div class="event-location">' + location + '</div>';
          html += '<div class="event-venue">' + event.venue.name + '</div>';
          html += '<div class="event-links">';
          for ( let offer of event.offers ) {
            html += '<a href="' + offer.url + '" target="_blank" class="btn btn-red-outline btn-rounded btn-small">' + offer.type + '</a>';
          }
          html += '</div>';
          html += '</div>';
        }
        events.html( html );
        } else {
          events.html( 'No upcoming events.' );
        }
      }
    });
  }

});